import { Injectable, ComponentFactoryResolver } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { SetUserAction, DeactivateUserAction } from '../redux/user.actions';
import { Router } from '@angular/router';
import { SetCategorieAction } from '../redux/categories.actions';
import { SetCategorieDoctorsAction } from '../redux/categories-doctors.actions';
import { ChangeTitleNav, ActivateLoadingAction, DeactivateLoadingAction } from '../redux/ui.actions';
import { GetChatHistory } from '../redux/chat-history.actions';
import moment from 'moment';
import { environment } from 'src/environments/environment.prod';
// import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private publicKey = "";
  private secureIV = "";
  private privateKey = "";
  public token = "";

  // URL Produccion
  // public newUrl = "https://apignp.mindhelp.mx/api/v1/";
  // URL Develop
  public newUrl = environment.apimh_url//"https://apignp.mindhelp.dev/api/v1/";
  // URL Local
  //public newUrl = 'http://192.168.100.35:1337/api/v1/';

  public conektaApiKey = "";
  public user = null;

  public PAYMETHODS = {cupon:1,tarjeta:2,tarjetacupon:3,creditos:4};

  constructor(private http: HttpClient, private store: Store<AppState>, private route: Router) { }

  conektaKey() {
    let url = `${this.newUrl}system/payments/keys`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,
    });

    this.http.get(url, { headers }).subscribe((d: any) => {
      let decUser = JSON.parse(this.decrypt(d.message, "private"));
      this.conektaApiKey = decUser.data.items[0].publicApiKey;
      console.log('APIKEY',this.conektaApiKey)
    });
  }

  login(user) {
    let url = `${this.newUrl}auth/local/signin`;
    console.log(url)

    this.store.dispatch(new ActivateLoadingAction());

    let encUser = { message: this.encrypt(user) };
    console.log('PAYLOAD:',encUser)
    this.http.post(url, encUser).subscribe((data: any) => {
      let decUser = JSON.parse(this.decrypt(data.message));
      let user = decUser.data.items[0];
      console.log('DECUSER',decUser)
      this.store.dispatch(new SetUserAction({ ...user }))
      this.user = user;
      localStorage.setItem('mindhelp_user',JSON.stringify(user))
      this.privateKey = user.encKey;
      console.log('USER:',this.user)
      this.token = user.token;
      this.store.dispatch(new DeactivateLoadingAction());
      if(this.user.account_status == "plan_unpaid")
        this.route.navigate(['panel/settings/tarjetas']);
      else
        this.route.navigate(['panel']);

    }, err => {
      this.store.dispatch(new DeactivateLoadingAction());
      let decData = JSON.parse(this.decrypt(err.error.message, 'public'));
      console.log(decData)
      Swal.fire({
        icon: 'error',
        title: 'Usuario inválido o contraseña inválida',
        showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        text: 'Intente de nuevo.',
      });
      // this.presentAlert(err.error.msg);
    })
  }

  register(user) {

    let url = `${this.newUrl}auth/local/signup/patient-mm`;

    let encUser = { message: this.encrypt(user) };
    console.log('ENCRYPTED DATA:',encUser)

    this.http.post(url, encUser).subscribe((data: any) => {
      console.log(data);
      let decUser = JSON.parse(this.decrypt(data.message));
      let user = decUser.data.items[0];

      this.store.dispatch(new SetUserAction({ ...user }))
      this.user = user;
      this.privateKey = user.encKey;
      this.token = user.token;
      if(this.user.account_status == "plan_unpaid")
        this.route.navigate(['panel/settings/tarjetas']);
      else
        this.route.navigate(['panel']);
      ////this.route.navigate(['panel']);

      Swal.fire({
        icon: 'success',
        title: 'Registro Correcto',
        text: "Se ha registrado con exito",
        showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
      });
    }, err => {
      if(err.name === "HttpErrorResponse"){
        Swal.fire({
          title: 'Error de conexión',
          text: 'Intente de nuevo más tarde.',
          icon: 'error',
          showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        });
      }
      console.log(err.error.message);
      let decData = JSON.parse(this.decrypt(err.error.message));
      console.log(decData)
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        text: decData.error.errors[0].message,
      });
    })
  }

  logout() {
    this.store.dispatch(new DeactivateUserAction());
    this.user = null;
    this.route.navigate(['/login']);
  }

  rePassRequest(data) {
    let url = `${this.newUrl}auth/reset-password/request`;
    let encUser = { message: this.encrypt(data) };

    return this.http.post(url, encUser);
  }

  rePassword(data) {
    let url = `${this.newUrl}auth/reset-password`;
    let encUser = { message: this.encrypt(data) };

    return this.http.post(url, encUser);
  }

  isAuth(): boolean {
    console.log(this.user)
    console.log('USUARIO',JSON.parse(localStorage.getItem('mindhelp_user')))
    
    if (localStorage.getItem('mindhelp_user') != null) {
      this.user = JSON.parse(localStorage.getItem('mindhelp_user'))
      this.privateKey = this.user.encKey;
      this.token = this.user.token;
      this.store.dispatch(new ActivateLoadingAction());
      this.store.dispatch(new SetUserAction({ ...this.user }))
      //this.getDoctors(1)
      this.store.dispatch(new DeactivateLoadingAction());

      return true;
      } else {
      this.route.navigate(['landing']);
      return false;
    }
  }

  /**
   *
   *
   * @param {*} data la informacion que encriptaras
   * @param {string} [type="public"] si la llave que usaras sera publica o privada
   * @returns retorna la data encryptada
   * @memberof AuthService
   */
  encrypt(data: any, type: string = "public") {
    let key = (type == "public") ? "OnnptHpzXXyREqAbmRYx0RCgp1vMRoHA" : this.privateKey;
    this.getKey(key);

    console.log('PRIVATE KEY:', this.privateKey)

    //let _key = CryptoJS.enc.Utf8.parse(this.publicKey);
    let _key = CryptoJS.enc.Utf8.parse(this.publicKey);
    let _iv = CryptoJS.enc.Utf8.parse(this.secureIV);

    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(data), _key, {
      keySize: 128,
      iv: _iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }

  /**
   *
   *
   * @param {*} data la informacion que desencriptaras
   * @param {string} [type="public"] si la llave que usaras sera publica o privada
   * @returns retorna la data desencryptada
   * @memberof AuthService
   */
  decrypt(data: any, type: string = "public") {
    
    let key = (type == "public") ? "OnnptHpzXXyREqAbmRYx0RCgp1vMRoHA" : this.privateKey;
                                  //OnnptHpzXXyREqAbmRYx0RCgp1vMRoHA
    this.getKey(key);
    

    let _key = CryptoJS.enc.Utf8.parse(this.publicKey);
    let _iv = CryptoJS.enc.Utf8.parse(this.secureIV);

    let decrypted = CryptoJS.AES.decrypt(
      data, _key, {
      keySize: 128,
      iv: _iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8)
    return decrypted;
  }

  getKey(key) {
    this.secureIV = key.substr(0, 16);
    this.publicKey = key;
  }

  getSpecialities() {
    let url = `${this.newUrl}system/categories`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    this.http.get(url, { headers }).subscribe({
      next:(d: any) => {
        let data = JSON.parse(this.decrypt(d.message));
        this.store.dispatch(new SetCategorieAction([...data.data.items]))
      },
      error(err){
        console.log(err)}
    })
  }

  getDoctors(id) {

    this.store.dispatch(new ActivateLoadingAction());

    //let url = `${this.newUrl}system/categories/${id}/doctors`;
    let startAt = (moment().startOf('month').format('YYYY-MM-DD'));
    let endAt = (moment().endOf('month').format('YYYY-MM-DD'));
    let start = `${startAt} 01:00:00`
    let end = `${endAt} 23:59:00`
    let url = `${this.newUrl}ecomm/users/${id}/time-slot-by-day?startDate=${start}&endDate=${end}`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,
    });

    this.http.get(url, { headers }).subscribe((data: any) => {

      let decUser = JSON.parse(this.decrypt(data.message,"public"));

      //this.store.dispatch(new DeactivateLoadingAction());

      let doctors = decUser.data.items;

      this.store.dispatch(new SetCategorieDoctorsAction([...doctors]))

      let textTitle = `Hay ${doctors.length} terapeutas disponibles en esta categoría`;
      this.store.dispatch(new ChangeTitleNav(textTitle));
      this.store.dispatch(new DeactivateLoadingAction());
    }, err => {
      if(err.name === "HttpErrorResponse"){
        Swal.fire({
          title: 'Error de conexión',
          text: 'Intente de nuevo más tarde.',
          icon: 'error',
          showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        });
      }
      this.store.dispatch(new DeactivateLoadingAction());
      console.log(err)
    })
  }

  getDoctorsFilter(d) {
    let url = `${this.newUrl}ecomm/users/${d.users}/time-slot-by-day?startDate=${d.startDate}&endDate=${d.endDate}&searchTerm=${d.searchTerm}&gender=${d.gender}`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,
    });
    this.http.get(url, { headers }).subscribe((data: any) => {

      console.log(data)
      let decUser = JSON.parse(this.decrypt(data.message,"public"));

      this.store.dispatch(new DeactivateLoadingAction());

      let doctors = decUser.data.items;
      // let doctors = data.data.items;

      this.store.dispatch(new SetCategorieDoctorsAction([...doctors]))

      let textTitle = `Hay ${doctors.length} terapeutas disponibles en esta categoría`;
      this.store.dispatch(new ChangeTitleNav(textTitle));

    }, err => {
      if(err.name === "HttpErrorResponse"){
        Swal.fire({
          title: 'Error de conexión',
          text: 'Intente de nuevo más tarde.',
          icon: 'error',
          showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        });
      }
      this.store.dispatch(new DeactivateLoadingAction());
      console.log(err)
    })
  }

  getDoctorDetail(id, cat_id) {
    //let url = `${this.newUrl}users/${id}`;
    let startAt = (moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'));
    let endAt = (moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'));
    let url = `${this.newUrl}ecomm/users/${cat_id}/time-slot-by-day?startDate=${startAt}&endDate=${endAt}&userId=${id}`;

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.get(url, { headers });
  }

  getDoctorTimeList(id, time) {
    let url = `${this.newUrl}users/${id}/time-slots`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,
    });

    const params = new HttpParams().set('time_as_reference', time);

    return this.http.get(url, { headers, params });
  }

  getDoctorTimeListOtherApi(id, time) {
    let url = `${environment.apimh_url}users/${id}/time-slots`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,
    });

    const params = new HttpParams().set('time_as_reference', time);

    return this.http.get(url, { headers, params });
  }

  getHours(id, time, view) {
    let url = `${this.newUrl}ecomm/users/find-availability-by-doctor`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,
    });

    const params = new HttpParams().set('time_as_reference', time)
      .set('time_type', 'month')
      .set('time_slot_type', 'hasAvailability')
      .set('userId', id);

    return this.http.get(url, { headers, params });
  }

  applyCupon(data) {
    let url = `${this.newUrl}system/promo-codes/verify`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    console.log('TOKEN', this.token)
    let encData = { message: this.encrypt(data, "private") };

    return this.http.post(url, encData, { headers });
  }

  saveBooking(data) {
    let url = `${this.newUrl}appointments`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    let encData = { message: this.encrypt(data, "private") };
    console.log('ENCDATA:',encData)
    console.log('DATA', data)

    return this.http.post(url, encData, { headers });
  }

  savePayedBooking(data) {
    data.id_user = this.user.id
    console.log('DATOS', data)
    let url = `${this.newUrl}payment/pay`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    let encData = { message: this.encrypt(data, "private") };
    return this.http.post(url, encData, { headers });
  }

  getProfile(id) {
    let url = `${this.newUrl}users/${id}`;
    console.log('URL:',url)
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    console.log('HEADERS:',headers)
    return this.http.get(url, { headers });
  }

  editProfile(data, id) {
    let url = `${this.newUrl}users/${id}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    });
    let encData = { message: this.encrypt(data, "private") };

    return this.http.patch(url, encData, { headers });
  }

  getChats(id) {
    let url = `${this.newUrl}users/${id}/chats`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.get(url, { headers });
  }

  getChatHistory(userId, chatId) {
    let url = `${this.newUrl}users/${userId}/chats/${chatId}/messages`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    this.http.get(url, { headers }).subscribe((d: any) => {
      let decUser = JSON.parse(this.decrypt(d.message, "private"));

      this.store.dispatch(new GetChatHistory([...decUser.data.items]))
    }, err => {
      if(err.name === "HttpErrorResponse"){
        Swal.fire({
          title: 'Error de conexión',
          text: 'Intente de nuevo más tarde.',
          icon: 'error',
          showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        });
      }
      console.log(err)
    });
  }

  sendMessage(userId, chatId, data) {
    let url = `${this.newUrl}users/${userId}/chats/${chatId}/messages`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    let encData = { message: this.encrypt(data, "private") };

    return this.http.post(url, encData, { headers });
  }

  getTimeZones() {
    return this.http.get("https://worldtimeapi.org/api/timezone")
  }

  getInfoTimeZone(timezone){
    if(timezone){
      return this.http.get(`https://worldtimeapi.org/api/timezone/${timezone}`)
    }
  }

  //  funtion to get the dates
  datesToConfirm(id) {

    let url = `${this.newUrl}users/${id}/appointments/status/to-confirm`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.get(url, { headers });
  }

  datesToBeCancelled(id) {
    let url = `${this.newUrl}users/${id}/appointments/status/to-cancel`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.get(url, { headers });
  }

  datesCancelled(id) {
    let url = `${this.newUrl}users/${id}/appointments/status/cancelled`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.get(url, { headers });
  }

  datesFinish(id) {
    let url = `${this.newUrl}users/${this.user.id}/appointments/${id}/status/finished`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.post(url, {}, { headers });
  }

  inquiriesFinish(id,userId) {
    let url = `${this.newUrl}users/${userId}/inquiries/${id}/status/finished`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.post(url, {}, { headers });
  }

  endDatesFinish(limit = 10,skip=0) {

    let url = `${this.newUrl}users/${this.user.id}/appointments/status/finished`
          +(limit > 0 ? `?limit=${limit}`:``)+(skip > 0 ? `&skip=${skip}`:``);
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.get(url, { headers });

  }

  datesScheduled(id) {
    let url = `${this.newUrl}users/${id}/appointments/status/scheduled`;
    console.log('URL',url)
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.get(url, { headers });
  }

  datesToBeConfirmed(id) {
    let url = `${this.newUrl}users/${id}/appointments/status/to-confirm`;
    console.log('URL',url)
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.get(url, { headers });
  }

  datesInProgress(id) {
    let url = `${this.newUrl}users/${id}/appointments/status/in-progress`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.get(url, { headers });
  }

  conectDate(userId, dateId) {
    let url = `${this.newUrl}users/${userId}/appointments/${dateId}/status/connected`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.post(url, {}, { headers });
  }

  conectInquiry(userId, dateId) {
    let url = `${this.newUrl}users/${userId}/inquiries/${dateId}/status/connected`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.post(url, {}, { headers });
  }

  // finish

  sendReview(id, data) {
    let url = `${this.newUrl}appointments/${id}/reviews`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    let encData = { message: this.encrypt(data, "private") };

    return this.http.post(url, encData, { headers });
  }

  getReviews(idDoctor) {
    let url = `${this.newUrl}users/${idDoctor}/reviews`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.get(url, { headers });
  }

  getImgId(id) {
    let url = `${this.newUrl}system/media/${id}`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.get(url, { headers })
  }

  contactUs(data) {
    let url = `${this.newUrl}system/emails/send-contact-form`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,
    });

    let encData = { message: this.encrypt(data, "private") };

    this.http.post(url, encData, { headers }).subscribe((d: any) => {
      Swal.fire({
        icon: 'success',
        title: 'Mensaje enviado',
        text: "En un momento nos comunicaremos con usted",
        showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
      });
    }, err => {
      if(err.name === "HttpErrorResponse"){
        Swal.fire({
          title: 'Error de conexión',
          text: 'Intente de nuevo más tarde.',
          icon: 'error',
          showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        });
      }
      let decData = JSON.parse(this.decrypt(err.error.message, "private"));
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        text: decData.error.errors[0].message,
      });
    });
  }

  // reBooking
  moveBooking(id,data) {
    let url = `${this.newUrl}appointments/${id}/move`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    let encData = { message: this.encrypt(data, "private") };
    console.log('ENCRYPTED DATA:',encData)
    console.log('TOKEN:', this.token)
    return this.http.post(url, encData, { headers });
  }

  cancelBooking(body, bookin) {
    console.log('BOOKIN', bookin)
    let url = `${this.newUrl}users/${this.user.id}/appointments/${bookin.id}/status/cancelled`;
    console.log('URL',url)
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,
    });
    //let encryptedBody = { text: this.encrypt(body, "private") };
    return this.http.post(url,body,{headers}) 
  }

  // Suscriptions and plans

  getPlans() {
    let url = `${this.newUrl}system/payments/plans`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.get(url, { headers });
  }

  addSuscription(idSub, data) {
    let url = `${this.newUrl}users/${this.user.id}/payments/suscriptions/${idSub}`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    // let encData = { message: this.encrypt(data, "private") };

    return this.http.post(url, {}, { headers });
  }

  deleteSuscription(idSub?) {
    let url = `${this.newUrl}users/${this.user.id}/payments/suscriptions/`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    // let encData = { message: this.encrypt(data, "private") };

    return this.http.delete(url, { headers });
  }

  getCurrentSuscription() {
    let url = `${this.newUrl}users/${this.user.id}/payments/suscriptions/active`;
    console.log('URL:',url)
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    console.log('CABEZAS:',headers)

    return this.http.get(url, { headers });
  }

  getCurrentCompany(){
    if(this.user.company_id){
      let url = `${this.newUrl}admin/companies/${this.user.company_id}`;
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token
      });
      return this.http.get(url, { headers });
    }else{
      return null;
    }
  }

  getUsesUser(){
    let url = `${this.newUrl}users/${this.user.id}/uses`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.get(url, { headers });
  }

  deleteRecipe(data){
    let url = `${this.newUrl}users/${data.folio}/recipes/`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.delete(url, { headers });
  }

  getRecipes(){
    let url = `${this.newUrl}users/${this.user.id}/recipes/1`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.get(url, { headers });
  }

  setRecipe(data){
    let url = `${this.newUrl}users/${this.user.id}/recipes`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    let encData = { message: this.encrypt(data,"private") };

    return this.http.post(url, encData , { headers });
  }

  /*getUsesUser(){
    let url = `${this.newUrl}users/${this.user.id}/uses`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.get(url, { headers }).subscribe((res: any) =>{
      let encData = JSON.parse(this.decrypt(res.message, 'private'));
      if(encData.data.items.length > 0){
        this.user.max_uses = encData.data.items[0].max_uses;
        this.user.uses = encData.data.items[0].uses;
      }
    },()=>{});
  }*/

  getUnusedPromoCodes(id){
    let url = `${this.newUrl}/system/find-user-promo-codes?patient_user_id=${id}`
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.get(url, { headers });
  }
  
  getUserCards(){
    console.log(this.user.id)
    let url = `${this.newUrl}cards/get?id_user=${this.user.id}`
    console.log('URL', url)
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.get(url, { headers });
  }

  addCard(data) {
    data.id_user = this.user.id
    console.log('CUERPO:', data)
    let url = `${this.newUrl}cards/create`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    let encData = { message: this.encrypt(data, "private") };

    return this.http.post(url, encData, { headers });
  }

  deleteCard(id) {
    const body = {card_token:id, id_user:this.user.id}
    console.log(body)
    let url = `${this.newUrl}cards/delete`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    let encData = { message: this.encrypt(body, "private") };

    return this.http.post(url, encData, { headers });
  }

  confirmAppointment(id){
    let url = `${this.newUrl}appointments-confirm?id=${id}`
    console.log('URL', url)
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.get(url, { headers });
  }
}
